
  // lost and found 
  function lostfound_price(weight) {
    var place = $('input[name="place_id"]').val();
    var url = $('.product.lostfound').attr('data-url');

    if($('.lostfound_numbers .field').length > 0 && place.length > 0 ) {
      
      var packs = [];
      var data = {};
      data.length = 0;
      data.width = 0;
      data.height = 0;
      data.weight = weight;
      data.count = 1;

      packs.push(data);

      $.ajax({
          type: "POST",
          url: url + '/ajax/call.php',
          data: {
            placeID: place,
            packs: packs,
            type: 'lostandfound'
          },                
          success: function(val){
            if(val !== 'false') {
              var data = jQuery.parseJSON(val);
              var html = '';
              var tax = 1;

              $.each(data, function(k, v) {
                if(v !== 'false' && v !== 'quotation'){
                  $('.no_value').hide();
                  $('.type_send').show();
                  $('.on_request').hide();
                  
                  console.log(data);

                  if(typeof data.info.region.length !== 'undefined'){
                    $('input[name="region"]').val(data.info.region);

                    if(data.info.region === 'Europe' || data.info.region === 'europe') {
                      tax = 1.21;

                      if(v.country == 'Switzerland') {
                        tax = 1;
                      } else if(v.country == 'Norway') {
                        tax = 1;
                      }

                      $('.product.woocommerce').attr('data-tax', tax);

                    } else {
                      $('.product.woocommerce').attr('data-tax', '1');
                      tax = 1;
                    }
                  }

                  if(v.type == 'courier') { 
                    total_ex = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2); 
                    total = parseFloat(Math.round((v.price * tax) * 100) / 100).toFixed(2);
                    vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);

                    price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                    discount = v.discount;

                    $('.type_send #econ').show();
                    $('#econ span.econ').html('Economy <span class="price">&euro;'+ (total) + '</span>');
                    $('#econ input').attr('data-price', v.price);

                    if(discount > 0) {
                      $('#econ .discount').show();
                      $('#econ .discount span').html(discount + '%');
                    }

                  }

                  if(v.type == 'courier_prio') {
                    total_ex = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2);
                    total = parseFloat(Math.round((v.price * tax) * 100) / 100).toFixed(2);
                    vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);

                    price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                    discount = v.discount;

                    $('.type_send #prio').show();
                    $('#prio span.prio').html('Priority <span class="price">&euro;'+ (total) + '</span>');
                    $('#prio input').attr('data-price', v.price);

                    if(discount > 0) {
                      $('#prio .discount').show();
                      $('#prio .discount span').html(discount + '%');
                    }
                  }

                  $('input[name="total_price"]').val($('input[name="send_type"]:checked').attr('data-price'));
                  $('input[name="discount"]').val($('input[name="send_type"]:checked').parent().find('.discount span').text());

                } else {
                  $('.line.vat').hide();
                  $('.line.cost_lf').hide();
                  $('.on_request').show();
                  $('.type_send').hide();
                  $('.type_send #econ').hide();
                  $('.type_send #prio').hide();
                }
                
              });
            } else {
              $('.no_value').show();
              $('.on_request').hide();
              $('.type_send').hide();
              $('.type_send #econ').hide();
              $('.type_send #prio').hide();
              $('input[name="total_price"]').val(0);
            }
          }
      });
    }
  }

  function item_change(){
    // calc content
    var wrap = $('#item_count');
    var weight = 0;
    var items = 0;
    var post_items = '';

    $('.lostfound_numbers .field').each(function(){
      weight += parseFloat($(this).attr('data-weight'));
      items++;
      post_items += $(this).attr('data-type') + ',';
    });

    wrap.find('.count').html(items);
    wrap.find('.count_weight').html(weight);

    $('input[name="lost_items"]').val(post_items);
    
    // resive slick
    var checkout = $(".checkout_slider");
    checkout.css('height', $('.checkout_slider .slide.active').outerHeight());

    lostfound_price(weight);
  }

  function add_item(obj) {
    var select = obj;
    var list = $('.choosen');
    var numbers = $('.lostfound_numbers');
    var item = $('.choose_type').attr('data-item');
    var img = select.parent().find('img').attr('src');

    if(select.length > 0){
      numbers.find('.no_items').hide();

      //list.append('<li data-item="'+ item +'" data-weight="'+ select.attr('data-weight') +'" data-type="'+ select.attr('data-name') +'"><div class="icon liquid" data-imgLiquid-fill="false"><img src="'+ img +'"></div> <span class="name">'+ select.attr('data-name')  +'</span><i class="fa fa-times" aria-hidden="true"></i></li>');
      
      input = '';
      input += '<div data-weight="'+ select.attr('data-weight') +'" data-type="'+ select.attr('data-name') +'" data-item="'+ item +'" class="field" id="date_pickup">';
      input += '<div class="icon"><img src="'+ img +'"></div> <div class="input_wrap"><div class="label">';
      input += select.attr('data-name')  +' <i class="fa fa-times" aria-hidden="true"></i></div>';
      input += '<input maxlength="6" type="number" placeholder="L&amp;F Registration number"';

      if(item == 1) {
        input += ' required';
      }

      input += ' name="lostfound_number_'+ item +'"></div></div>';

      numbers.append(input);

      $('.liquid').imgLiquid();
      $('.choose_type').attr('data-item', parseInt(item) + 1);

      item_change();
      $("html, body").velocity("scroll", {duration:600, offset: ($('.checkout_slider').offset().top - 80)});

    }
  }
  $('.add-item').click(function(){
    add_item($(this));
  });

  $('.choose_type .item').click(function(){
    if($('.lostfound_numbers .field').length == 0){
      add_item();
    }
  });
  
  function lostfoundinsurance() {
    var tax = $('.product.woocommerce').attr('data-tax');
    if($('input[name="insurance_active"]').is(':checked')) {
      var shipment_value = $('.checkout input[name="shipment_value"]').val().replace(/,/g, '.');

      if(shipment_value > 250000) {
        var insurance_price = (((250000 / 100) * 2) + 12.5) * tax;
        var insurance_price_no_tax = (((250000 / 100) * 2) + 12.5);
      }else if(shipment_value > 2500) {
        var insurance_price = (((shipment_value / 100) * 2) + 12.5) * tax;
        var insurance_price_no_tax = (((shipment_value / 100) * 2) + 12.5);
      } else {
        var insurance_price = (32.5 + 12.5) * tax;
        var insurance_price_no_tax = (32.5 + 12.5);
      }


      $('.insurance label span').html('(&euro;' + insurance_price.toFixed(2) + ')');
      $('input[name="insurance"]').val(insurance_price_no_tax.toFixed(2));

      $('.line#insurance').show();
      $('.line#insurance .price').html('&euro;'+ insurance_price.toFixed(2));
    } else {
      $('.line#insurance').hide();
      $('.insurance label span').html(''); 
      $('input[name="insurance"]').val(0);
    }
  }

  $('.lostfound input[name="insurance_active"]').change(function(){
    lostfoundinsurance();
  });

  $('.lostfound input[name="shipment_value"]').change(function(){
    lostfoundinsurance();
  });

  $('.lostfound input[name="send_type"]').change(function(){
    if($(this).is(':checked')) {

      tax = $('.product.woocommerce').attr('data-tax');

      var selected_total = $(this).attr('data-price');
      select_ex = parseFloat(Math.round((selected_total) * 100) / 100).toFixed(2);
      select_total = parseFloat(Math.round((selected_total * tax) * 100) / 100).toFixed(2);
      select_vat = parseFloat(Math.round((select_total - select_ex) * 100) / 100).toFixed(2);


      $('.line.vat').show();
      $('.line.vat #vat').html('&euro;'+ select_vat);

      $('.line.cost_lf').show();
      $('.line.cost_lf .price').html('&euro;'+ selected_total);

      $('input[name="total_price"]').val(selected_total);
      $('input[name="discount"]').val($(this).parent().find('.discount span').text());
    }
  });

  $(document).on('click', '.lostfound_numbers .field .fa-times', function(){
    var div = $(this).parent().parent().parent();
    var item = div.attr('data-item');
    div.remove();

    $('.lostfound_numbers div[data-item="'+ item +'"]').remove();
    item_change();

    if($('.lostfound_numbers .field').length == 0){
      $('.lostfound_numbers').find('.no_items').show();
    }
  });

  $(document).on('click', '.type_send label', function(){
    $('.type_send label').removeClass('active');
    $(this).addClass('active');
  });